/*eslint-disable*/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import pattern from "assets/img/pattern_react.png";
import googlePlayStore from "assets/img/Google_Play_Store_badge_EN.png";
import splashScreen from "assets/img/splash_screens.png";
import socialScreen from "assets/img/social_screen.png";
import tradeScreen from "assets/img/trade_screen.png";
import profitScreen from "assets/img/profit_screen.png";
import documentScreen from "assets/img/document_screen.png";

export default function Index() {
  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);

    const paramScreen = params.get("screen");
    const paramPostsId = params.get("posts_id");

    if (paramScreen && paramPostsId) {
      const encodedString = encodeURIComponent(
        `https://trucks.page.link/app?screen=${paramScreen}&posts_id=${paramPostsId}`
      );
      window.location.href = `https://trucks.page.link?link=${encodedString}`;
    }

    if (paramScreen && !paramPostsId) {
      const encodedString = encodeURIComponent(
        `https://trucks.page.link/app?screen=${paramScreen}`
      );
      window.location.href = `https://trucks.page.link?link=${encodedString}`;
    }
  }, []);
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                트럭스 - 트러커에게 필요한 모든 것
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                우리나라 모든 트러커와 소통하고,
              </p>
              <p className="text-lg leading-relaxed text-blueGray-500">
                트러커의 시작과 끝을 함께하고,
              </p>
              <p className="text-lg leading-relaxed text-blueGray-500">
                트럭을 관리하고,
              </p>
              <p className="text-lg leading-relaxed text-blueGray-500">
                영업에 필요한 모든 서류를 보관하고,
              </p>
              <p className="text-lg leading-relaxed text-blueGray-500">
                영업으로 발생한 손익을 한눈에.
              </p>
              <div className="mt-5">
                <button
                  type="button"
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                >
                  <img
                    className="w-auto h-12 align-middle border-none"
                    src={googlePlayStore}
                    alt="..."
                    onClick={() => {
                      alert("준비중...");
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={pattern}
          alt="..."
        />
      </section>

      <section className="mt-48 md:mt-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-12/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col p-3 min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src={splashScreen}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    구글 플레이 스토어에서 트럭스를 검색해보세요.
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    트러커의 즐거운 일상을 위해 만들었습니다. 언제, 어디서든
                    트러커가 도움을 드릴게요. 즐거운 일상을 우리 모두 함께해요.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-sitemap"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        SNS 커뮤니티
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        즐거운 일, 기쁜 일, 행복한 일, 슬픈 일, 모든 일을 우리
                        함께 나눠요.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-truck"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        차량매매 커뮤니티
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        화물운전을 준비중이시라면 차량매매 커뮤니티에서 알맞는
                        일자리를 찾아보세요.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-chart-line"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">손익관리</h6>
                      <p className="mb-4 text-blueGray-500">
                        영업을 통해 발생하는 운송수입을 관리하고 순수입을
                        확인해서 가계 지출계획을 세워봐요.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-file-alt"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">서류관리</h6>
                      <p className="mb-4 text-blueGray-500">
                        필요할 때 언제든지 찾을 수 있게, 화물운송을 하면서
                        발생하는 각종 서류를 트럭스 앱에 모아서 관리해 봐요.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-24">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-sitemap text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                SNS 커뮤니티
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                오늘 하루는 어떠셨나요? 주변 동료들과 함께 나누고 싶은 이야기가
                있으신가요? 나만의 이야기를 들려주세요. 함께 기뻐하고 슬퍼할
                준비가 되어 있어요.
              </p>
            </div>
            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={socialScreen}
                  className="w-full align-middle rounded-t-lg"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center pt-32">
            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={tradeScreen}
                  className="w-full align-middle rounded-t-lg"
                />
              </div>
            </div>
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-truck"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                차량매매 커뮤니티
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                화물운전종사자격이 있다면, 트러커가 되어 보시는 건 어떠세요?
                트럭스 앱에서 다양한 화물운전 일자리를 찾아보고 내게 맞는
                일자리를 골라보세요.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-blueGray-600 overflow-hidden">
        <div className="container mx-auto px-4 pb-32">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-chart-line"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                  손익관리
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-300">
                  이번달 운송수입을 얼마인지, 세금계산서는 잘 발행했는지, 입금은
                  잘 되었는지, 관리하기 힘들지 않으셨나요? 트럭스 앱에
                  운송수입과 운송비용을 기록하고 관리해 보세요. 미래 지출계획을
                  세울 수 있어요.
                </p>
              </div>
            </div>
            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={profitScreen}
                  className="w-full align-middle rounded-t-lg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto pb-64">
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={documentScreen}
                  className="w-full align-middle rounded-t-lg"
                />
              </div>
            </div>
            <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-file-alt"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                서류관리
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-300">
                트러커의 바쁜 일상 속에서 어쩌다가 한번 찾아보게 되는 서류들,
                필요할 때는 꼭 못 찾지 않으셨나요? 이제는 이런 서류들을 트럭스
                앱에서 보관해보세요. 필요할 때 손쉽게 찾아볼 수 있어요.
              </p>
            </div>

            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative"></div>
          </div>
        </div>
      </section>

      <section className="pb-16 bg-blueGray-200 relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full text-center lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  😍
                </span>
              </p>
              <h3 className="font-semibold text-3xl">트럭스 앱 어때요?</h3>
              <p className="text-blueGray-500 text-lg leading-relaxed mt-4">
                아직 부족하다구요?
              </p>
              <p className="text-blueGray-500 text-lg leading-relaxed">
                그럼, 아래 요청하기를 눌러서
              </p>
              <p className="text-blueGray-500 text-lg leading-relaxed">
                트러커 생활에 필요한 기능을 알려주세요.
              </p>
              <p className="text-blueGray-500 text-lg leading-relaxed mb-4">
                즐거운 트러커 생활을 위해 트럭스 앱을 우리 같이 만들어 봐요.
              </p>
              <div className="sm:block flex flex-col mt-10">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus?ref=nr-index"
                  target="_blank"
                  className="get-started text-white font-bold px-12 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  요청하기
                </a>
              </div>
              <div className="text-center mt-16"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
